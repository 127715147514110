var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"js-container js-content"},[_c('section',{staticClass:"js-main"},[_c('div',{staticClass:"js-main__l"},[_vm._m(0),_c('div',[_c('section',{staticClass:"js-FeaturedColumn_container swiper-no-swiping swiper-container swiper-container-initialized swiper-container-horizontal"},[(_vm.popularColumn.length > 0)?_c('div',{staticClass:"js-FeaturedColumn_title"},[_c('div',{staticClass:"js-FeaturedColumn_title-content justify_content_sb",staticStyle:{"width":"100%"}},[_c('p',{staticClass:"js-FeaturedColumn_title-info"},[_vm._v("精选专栏")]),_c('div',{staticClass:"js-FeaturedColumn_title-more",on:{"click":function($event){return _vm.$router.push({ path: '/zl_list' })}}},[_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"}),_c('div',{staticClass:"rsTitleLeft_point"})])])]):_vm._e(),(_vm.popularColumn.length > 0)?_c('div',{staticClass:"js-FeaturedColumn_Datalist",staticStyle:{"transform":"translate3d(0px, 0px, 0px)"}},[_c('div',{staticClass:"js-FeaturedColumn_DataItem swiper-slide-active",staticStyle:{"width":"100%"}},[_c('ul',{staticClass:"js-FeaturedColumn_list"},_vm._l((_vm.popularColumn),function(item,popularColumnIndex){return _c('li',{key:popularColumnIndex,staticClass:"js-FeaturedColumn_item pointer",on:{"mouseover":function($event){return _vm.onMouseEnter(popularColumnIndex)}}},[_c('a',[(
														item.userInfo != null &&
														item.userInfo.avatar_img != '' &&
														item.userInfo.avatar_img != null
													)?_c('img',{staticClass:"js-FeaturedColumn_item-avatar",staticStyle:{"object-fit":"cover"},attrs:{"src":item.userInfo.avatar_img,"title":item.userInfo.nickname,"alt":"avatar"},on:{"click":function($event){return _vm.$router.push({
															path: '/home_wz',
															query: { id: item.userInfo.id },
														})}}}):_c('img',{staticClass:"js-FeaturedColumn_item-avatar",attrs:{"src":"https://zy.metaera.media/user_header.png","alt":""}}),_c('img',{staticClass:"js-FeaturedColumn_item-authentication",attrs:{"src":"https://zy.metaera.media/assets/images/home/vip.png"}}),(
														item.userInfo != null &&
														item.userInfo.nickname != '' &&
														item.userInfo.nickname != null
													)?_c('p',{staticClass:"js-FeaturedColumn_item-nickname"},[_vm._v(" "+_vm._s(item.userInfo.nickname))]):_c('p',{staticClass:"js-FeaturedColumn_item-nickname"},[_vm._v(" MetaEra用户")]),_c('p',{staticClass:"js-FeaturedColumn_item-articles"},[_vm._v(" 文章："+_vm._s(item.information_num)+"篇")])]),_c('div',{},[(item.is_follow == 1)?_c('div',{staticClass:"js-FeaturedColumn_item-followed",on:{"click":function($event){return _vm.followAuthorFun(item.userInfo.id, 0)}}}):_c('div',{staticClass:"followMeta flex justify_content_c align_items_c",on:{"click":function($event){return _vm.followAuthorFun(item.userInfo.id, 1)}}},[_vm._v(" + 关注 ")])]),(_vm.hotCurrentIndexLeft == popularColumnIndex)?_c('div',{staticClass:"zl_hotCurrent_current"}):_vm._e()])}),0),_c('div',{staticClass:"zl_hotCurrent_line"}),_c('ul',{staticClass:"zl_author_articles_main"},_vm._l((_vm.infoLists),function(item,infoListsIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(infoListsIndex < 4),expression:"infoListsIndex < 4"}],key:infoListsIndex,staticClass:"zl_author_articles_item",on:{"click":function($event){return _vm.$router.push({
													path: '/gw_detail',
													query: { id: item.id },
												})}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"zl_author_articles_item_point"}),_c('div',{staticClass:"zl_author_articles_item_title pointer hovercolor"},[_vm._v(" "+_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title)+" ")])]),_c('div',{directives:[{name:"time",rawName:"v-time",value:(item.release_time),expression:"item.release_time"}],staticClass:"zl_author_articles_item_time"})])}),0)])]):_vm._e(),_c('span',{staticClass:"swiper-notification",attrs:{"aria-live":"assertive","aria-atomic":"true"}})])]),_c('div',{staticClass:"tageBox tageBoxZL"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},_vm._l((_vm.categoryLists),function(item,categoryListsIndex){return _c('el-tab-pane',{key:categoryListsIndex,attrs:{"label":_vm.localLanguage == 2
										? item.name_en
											? item.name_en
											: item.name
										: item.name,"name":item.id + ''}},[(_vm.articleList.length > 0)?_c('div',{staticClass:"wzitem_main"},[_vm._l((_vm.articleList),function(items,articleListIndexs){return _c('div',{key:articleListIndexs,staticClass:"zl_articleList_pc align_items_c pointer",on:{"click":function($event){return _vm.$router.push({
												path: '/gw_detail',
												query: { id: items.id },
											})}}},[_c('wzitem',{attrs:{"item":items}})],1)}),_vm._l((_vm.articleList),function(items,articleListIndexs1){return _c('div',{key:articleListIndexs1,staticClass:"zl_articleList_app",on:{"click":function($event){return _vm.$router.push({
												path: '/gw_detail',
												query: { id: items.id },
											})}}},[_c('PerNews',{attrs:{"item":items}})],1)}),_c('scrollToBottom',{on:{"reachBottom":_vm.getDataListPage}})],2):_vm._e()])}),1)],1)]),_c('div',{staticClass:"js-main__r"},[_c('div',{staticClass:"release-connent pointer",on:{"click":_vm.gotoMy}},[_c('img',{attrs:{"src":"https://zy.metaera.media/assets/images/zl/zl_publish.png","alt":""}})]),_c('authorList',{staticStyle:{"margin-top":"30px"},attrs:{"authorList":_vm.authorLists,"title":"周作者热榜"}}),_c('hot-article',{staticStyle:{"margin-top":"30px"},attrs:{"hotLists":_vm.hotLists,"title":"周文章热榜"}}),_c('download')],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"columns-herder"},[_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":"https://zy.metaera.media/assets/images/zl/zl_top.png","alt":""}})])
}]

export { render, staticRenderFns }