<template>
	<div>
		<div>
			<div class="js-container js-content">
				<section class="js-main">
					<div class="js-main__l">
						<div class="columns-herder">
							<img
								src="https://zy.metaera.media/assets/images/zl/zl_top.png"
								style="object-fit: contain"
								alt=""
							/>
						</div>
						<div>
							<section
								class="js-FeaturedColumn_container swiper-no-swiping swiper-container swiper-container-initialized swiper-container-horizontal"
							>
								<div
									class="js-FeaturedColumn_title"
									v-if="popularColumn.length > 0"
								>
									<div
										class="js-FeaturedColumn_title-content justify_content_sb"
										style="width: 100%"
									>
										<p class="js-FeaturedColumn_title-info">精选专栏</p>
										<div
											class="js-FeaturedColumn_title-more"
											@click="$router.push({ path: '/zl_list' })"
										>
											<div class="rsTitleLeft_point"></div>
											<div class="rsTitleLeft_point"></div>
											<div class="rsTitleLeft_point"></div>
										</div>
									</div>
								</div>
								<div
									class="js-FeaturedColumn_Datalist"
									style="transform: translate3d(0px, 0px, 0px)"
									v-if="popularColumn.length > 0"
								>
									<div
										class="js-FeaturedColumn_DataItem swiper-slide-active"
										style="width: 100%"
									>
										<ul class="js-FeaturedColumn_list">
											<li
												v-for="(item, popularColumnIndex) in popularColumn"
												:key="popularColumnIndex"
												class="js-FeaturedColumn_item pointer"
												@mouseover="onMouseEnter(popularColumnIndex)"
											>
												<a>
													<img
														v-if="
															item.userInfo != null &&
															item.userInfo.avatar_img != '' &&
															item.userInfo.avatar_img != null
														"
														@click="
															$router.push({
																path: '/home_wz',
																query: { id: item.userInfo.id },
															})
														"
														:src="item.userInfo.avatar_img"
														:title="item.userInfo.nickname"
														alt="avatar"
														class="js-FeaturedColumn_item-avatar"
														style="object-fit: cover"
													/>
													<img
														class="js-FeaturedColumn_item-avatar"
														src="https://zy.metaera.media/user_header.png"
														alt=""
														v-else
													/>
													<img
														src="https://zy.metaera.media/assets/images/home/vip.png"
														class="js-FeaturedColumn_item-authentication"
													/>
													<!---->
													<p
														class="js-FeaturedColumn_item-nickname"
														v-if="
															item.userInfo != null &&
															item.userInfo.nickname != '' &&
															item.userInfo.nickname != null
														"
													>
														{{ item.userInfo.nickname }}</p
													>
													<p class="js-FeaturedColumn_item-nickname" v-else>
														MetaEra用户</p
													>
													<p class="js-FeaturedColumn_item-articles">
														文章：{{ item.information_num }}篇</p
													>
												</a>
												<div style="">
													<div
														@click="followAuthorFun(item.userInfo.id, 0)"
														class="js-FeaturedColumn_item-followed"
														v-if="item.is_follow == 1"
													>
													</div>
													<div
														@click="followAuthorFun(item.userInfo.id, 1)"
														class="followMeta flex justify_content_c align_items_c"
														v-else
													>
														+ 关注
													</div>
												</div>

												<div
													class="zl_hotCurrent_current"
													v-if="hotCurrentIndexLeft == popularColumnIndex"
												></div>
											</li>
										</ul>
										<div class="zl_hotCurrent_line"> </div>
										<ul class="zl_author_articles_main">
											<div
												class="zl_author_articles_item"
												v-for="(item, infoListsIndex) in infoLists"
												:key="infoListsIndex"
												v-show="infoListsIndex < 4"
												@click="
													$router.push({
														path: '/gw_detail',
														query: { id: item.id },
													})
												"
											>
												<div class="flex">
													<div class="zl_author_articles_item_point"> </div>
													<div
														class="zl_author_articles_item_title pointer hovercolor"
													>
														{{
															localLanguage == 2
																? item.title
																	? item.title
																	: item.title
																: item.title
														}}
													</div>
												</div>
												<div
													class="zl_author_articles_item_time"
													v-time="item.release_time"
												>
												</div>
											</div>
										</ul>
									</div>
								</div>
								<span
									class="swiper-notification"
									aria-live="assertive"
									aria-atomic="true"
								></span>
							</section>
						</div>
						<div class="tageBox tageBoxZL">
							<el-tabs v-model="activeName" @tab-click="handleClick">
								<el-tab-pane
									:label="
										localLanguage == 2
											? item.name_en
												? item.name_en
												: item.name
											: item.name
									"
									:name="item.id + ''"
									v-for="(item, categoryListsIndex) in categoryLists"
									:key="categoryListsIndex"
								>
									<div v-if="articleList.length > 0" class="wzitem_main">
										<div
											class="zl_articleList_pc align_items_c pointer"
											v-for="(items, articleListIndexs) in articleList"
											:key="articleListIndexs"
											@click="
												$router.push({
													path: '/gw_detail',
													query: { id: items.id },
												})
											"
										>
											<wzitem :item="items"></wzitem>
										</div>
										<div
											class="zl_articleList_app"
											v-for="(items, articleListIndexs1) in articleList"
											:key="articleListIndexs1"
											@click="
												$router.push({
													path: '/gw_detail',
													query: { id: items.id },
												})
											"
										>
											<PerNews :item="items"></PerNews>
										</div>
										<scrollToBottom
											@reachBottom="getDataListPage"
										></scrollToBottom>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
					<div class="js-main__r">
						<div class="release-connent pointer" @click="gotoMy">
							<img
								src="https://zy.metaera.media/assets/images/zl/zl_publish.png"
								alt=""
							/>
						</div>
						<authorList
							:authorList="authorLists"
							title="周作者热榜"
							style="margin-top: 30px"
						>
						</authorList>
						<hot-article
							:hotLists="hotLists"
							title="周文章热榜"
							style="margin-top: 30px"
						>
						</hot-article>
						<download></download>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getPopularColumn,
	getArticleList,
	categoryLists,
	getZLHostLists,
	getWeekAuthorList,
} from "@/api/home";
import { followAuthor } from "@/api/user";
import { getToken } from "@/utils/auth";
import PerNews from "../home/mobile/perNews";
import wzitem from "./../../components/pc/wzitem.vue";
import download from "./../../components/pc/download.vue";
import hotArticle from "../home/hotArticle.vue";
import authorList from "../../components/pc/authorList.vue";
import { eventBus } from "../../components/pc/leftTab";
export default {
	name: "",
	components: { PerNews, wzitem, download, hotArticle, authorList },
	data() {
		return {
			activeName: "0",
			articleList: [],
			categoryLists: [],
			popularColumn: [],
			infoLists: [],
			noticeLists: [],
			authorLists: [],
			page: 1,
			size: 25,
			total: "",
			loading: false,
			hotLists: [],
			hotCurrentIndex: 1,
			hotCurrentIndexLeft: 0,
			localLanguage: 1,
		};
	},
	methods: {
		gotoMy() {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				this.$router.push({ path: "/publish", query: {} });
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		onMouseEnter(index) {
			this.hotCurrentIndexLeft = index;
			this.infoLists = this.popularColumn[index].infoList;
		},
		onMouseHotEnter(index) {
			this.hotCurrentIndex = index;
		},
		// 关注
		followAuthorFun(id, is_status) {
			const hasToken = getToken();
			// console.log(hasToken);
			if (hasToken) {
				followAuthor({ author_id: id, is_status: is_status })
					.then((res) => {
						if (res.code == 200) {
							this.$message.success("操作成功！");
							this.getPopularColumn();
						}
					})
					.catch((error) => {
						// this.$message.error(error.message);
					});
			} else {
				this.$PromptTip({ dialogVisible: true, showStatus: 1 });
			}
		},
		getPopularColumn() {
			getPopularColumn({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.popularColumn = res.data.list;
						if (this.popularColumn.length > 0) {
							this.infoLists = this.popularColumn[0].infoList;
						} else {
							this.infoLists = [];
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getWeekAuthorList() {
			getWeekAuthorList({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						if (res.data.list.length > 5) {
							this.authorLists = res.data.list.slice(0, 5);
						} else {
							this.authorLists = res.data.list;
						}
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		handleClick(val) {
			this.page = 1;
			this.articleList = [];
			this.getArticleList(
				{ category_id: val.name, type: 5, page: this.page, size: this.size },
				0
			);
		},
		getDataListPage() {
			if (this.loading) {
				return;
			}
			if (this.page >= Math.ceil(this.total / this.size)) {
				return;
			}
			this.loading = true;
			this.page = this.page + 1;
			this.getArticleList(
				{
					category_id: this.activeName,
					type: 5,
					page: this.page,
					size: this.size,
				},
				0
			);
		},
		getArticleList(obj, type) {
			getArticleList(obj)
				.then((res) => {
					if (res.code == 200) {
						this.loading = false;
						if (obj.page == 1) {
							this.articleList = res.data.list;
						} else {
							this.articleList = this.articleList.concat(res.data.list);
						}
						this.total = res.data.total;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getCategortLists() {
			categoryLists({ type: 5 })
				.then((res) => {
					if (res.code == 200) {
						this.categoryLists = res.data;
						if (res.data.length > 0) {
							this.activeName = res.data[0].id + "";
							this.getArticleList(
								{
									category_id: this.activeName,
									type: 5,
									page: this.page,
									size: this.size,
								},
								0
							);
						}
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		getZLHostLists() {
			getZLHostLists({ type: 10, page: this.page, size: this.size })
				.then((res) => {
					if (res.code == 200) {
						this.hotLists = res.data.list;
					} else {
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "简体中文") {
				this.localLanguage = 0;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 1;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
	},
	created() {
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "简体中文") {
			this.localLanguage = 0;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 1;
		}
		this.getPopularColumn();
		this.getCategortLists();
		this.getZLHostLists();
		this.getWeekAuthorList();
	},
};
</script>

<style>
.followMeta {
	font-size: 12px;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 12px;
	letter-spacing: 0em;

	width: 70px;
	height: 26px;
	border-radius: 8px;
	opacity: 1;

	/* 主色（一级颜色） */
	background: #0056ff;
}
.js-main .release-connent p,
.cloumn-week-of-rranking .cloumn-week-of-rranking-herder p .actove,
.cloumn-week-of-rranking .cloumn-week-of-rranking-herder p span:hover {
	background: #0056ff;
}
.js-tabs a.active {
	color: #0056ff;
	font-weight: 500;
	border-bottom: 2px solid #0056ff;
}
.js-cloumnArticle_list
	.js-cloumnArticle_item
	.js-cloumnArticle_item-authorInfo
	.js-cloumnArticle_item-follow {
	border: 1px solid #0056ff;
	border-radius: 15px;
	color: #0056ff;
}
.tageBox {
	margin-top: 20px;
}
.zl_articleList_pc {
	display: contents;
}
.zl_articleList_app {
	display: none;
}

.chanyeTitle .rsTitle {
	margin-top: 0 !important;
}
.zl_hotCurrent_line {
	margin-left: 39px;
	margin-top: -2px;
	width: 522px;
	height: 1px;
	opacity: 1;
	background: #e5e9fb;
}
.zl_hotCurrent_current {
	width: 82px;
	height: 2px;
	opacity: 1;
	margin-top: 22px;
	/* 主色（一级颜色） */
	background: #0056ff;
}
.zl_author_articles_main {
	padding-top: 26px;
	padding-top: 20px;
	width: 100%;
	height: 184px;
	flex-wrap: wrap;
	display: flex;
	background: white;
	border-bottom-right-radius: 12px;
	border-bottom-left-radius: 12px;
	gap: 0px;
}
.zl_author_articles_item {
	height: 70px;
	opacity: 1;
	width: 300px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.zl_author_articles_item_point {
	margin-left: 39px;
	margin-top: 2px;
	width: 6px;
	height: 6px;
	opacity: 1;
	background: #6974c8;
	border-radius: 3px;
}

.zl_author_articles_item_title {
	margin-left: 13px;
	width: 220px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 21px;
	color: #656878;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.zl_author_articles_item_time {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	color: #b2b2b2;
	margin-left: 58px;
}
</style>
<style>
@media screen and (max-width: 800px) {
	.tageBoxZL .el-tabs__item {
		margin-top: 10px;
		height: 30px !important;
		font-size: 16px !important;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif !important;
		font-weight: 600 !important;
		line-height: normal !important;
		align-items: center;
	}
	.zl_articleList_pc {
		display: none;
	}
	.zl_articleList_app {
		display: block;
	}
	.tageBox {
		margin-top: 6px;
		padding-left: 16px;
		padding-right: 16px;
	}
	.el-tabs__nav-prev,
	.el-tabs__nav-next,
	.el-tabs__nav-wrap::after {
		display: none;
	}
	.el-tabs__nav-scroll {
		overflow-x: scroll !important;
	}
	.el-tabs__nav-scroll::-webkit-scrollbar {
		display: none !important;
	}
	.el-tabs__nav-wrap.is-scrollable {
		padding: 0 0;
	}
	.zl_hotCurrent_current {
		display: none;
	}
	.zl_author_articles_item_title {
		width: 100%;
	}
	.zl_author_articles_item_point {
		margin-left: 16px;
	}
	.zl_author_articles_item_time {
		margin-left: 30px;
	}
	.zl_author_articles_main {
		height: auto;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	.zl_author_articles_item {
		height: 70px;
		opacity: 1;
		width: 95%;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}
</style>
