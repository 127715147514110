<template>
	<div class="eventWraper" v-if="allData.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c">
				<div style="font-weight: bold"
					>{{ title == null || title.length == 0 ? "作者列表" : title }}
				</div>
				<div
					class="flex align_items_c pointer justify_content_sb"
					@click="$router.push({ path: '/zl_list' })"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<div class="rsList eventWraper_content">
			<div
				class="rsItem flex pointer"
				v-for="(item, index) in allData"
				:key="index"
				@click="
					$router.push({
						path: '/home_wz',
						query: { id: item.userInfo.id },
					})
				"
			>
				<div class="reItem_Left">
					<img class="rsItemImg" :src="item.userInfo.avatar_img" alt="" />
				</div>
				<div class="reItem_Right">
					<div class="reItem_Left_title hovercolor"
						>{{ item.userInfo.nickname }}
					</div>
					<div class="reItem_Right_bottom flex">
						<div class="reItem_Left_line"></div>
						<div class="reItem_Left_time"
							>文章数：<span>{{ item.information_num }}</span></div
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["authorList", "title"],
	data() {
		return {
			allData: [],
		};
	},
	watch: {
		authorList: {
			handler(val) {
				this.toNewPage(1);
			},
			deep: true,
		},
		title: {
			handler(val) {
				this.title = val;
			},
			deep: true,
		},
	},
	mounted() {
		this.toNewPage(1);
	},
	created() {
		this.toNewPage(1);
	},
	methods: {
		toNewPage(toPage) {
			var tmpData = [];
			tmpData = tmpData.concat(this.authorList);
			this.allData = tmpData;
		},
	},
};
</script>

<style scoped>
.rsItem2Bor {
	border-bottom: 1px solid #f5f5f5;
	padding-right: 20px;
	width: 100%;
	height: 80px;
}

.rsItem2Bor:nth-last-child(1) {
	border-bottom: none;
}
.rsItem {
	height: 70px;
	/* width: 160px; */
}
.reItem_Left {
	width: 60px;
}
.reItem_Left_title {
	width: 100%;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0em;

	color: #000000;

	/* 黑色主色（字段一级） */
	color: #333333;

	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.reItem_Left_time,
.reItem_Left_time span {
	width: 135px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 13px;
	font-weight: normal;
	line-height: 13px;
	letter-spacing: 0em;
	margin-top: 13px;
	color: #b2b2b2;
}
.reItem_Right {
	margin-left: 15px;
	width: 160px;
}
.rsItemImg {
	width: 60px;
	height: 60px;
	border-radius: 30px;
	object-fit: cover;
}
.reItem_Left_line {
	background: #0056ff;
}
.reItem_Right_bottom {
	margin-top: 16px;
}
</style>
